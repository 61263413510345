import { render, staticRenderFns } from "./Leaders.vue?vue&type=template&id=259e15b8&scoped=true&"
import script from "./Leaders.vue?vue&type=script&lang=js&"
export * from "./Leaders.vue?vue&type=script&lang=js&"
import style0 from "./Leaders.vue?vue&type=style&index=0&id=259e15b8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "259e15b8",
  null
  
)

export default component.exports